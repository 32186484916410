.updateWrapper {
  height: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .contentWrapper {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1A1A1A;
    overflow-y: auto;
  }

  a {
    text-decoration: underline;
    color: #1869F5;
  }

  .btnWrapper {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
  }
}