@tailwind base;
@tailwind components;
@tailwind utilities;
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Roboto;
  src: url('../assets/font/Roboto-VariableFont.woff2') format('woff2'),
  url('../assets/font/Roboto-VariableFont.woff') format('woff'),
  url('../assets/font/Roboto-VariableFont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: JDZhengHT-EN;
  src: url('../assets/font/JDZhengHT-EN-Regular.woff2') format('woff2'),
  url('../assets/font/JDZhengHT-EN-Regular.woff') format('woff'),
  url('../assets/font/JDZhengHT-EN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --font-family: Roboto, "-apple-system", "BlinkMacSystemFont";
  --hover-state-color: #ff3f4f;
  --dp-hover-state-color: #990915;
  --btn-primary-bg: linear-gradient(90deg, #ff475d 0%, #ff0f23 100%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  font-family: var(--font-family);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
#jdi-header, #jdi-footer {
  min-width: 1264px;
  margin: 0 auto;
}

.w-e-text-container [data-slate-editor] .table-container {
  border: none !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

body img, svg, video, canvas, audio, iframe, embed, object {
  display: inline;
}
